/* global window document */
// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import setBiAction from '../../../../utils/setBiAction';
import Section from '../../../AutoLevels/Section';
import ListView from '../../../ListView/NewListView';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import { EventDate, EventTitle, EventParticipates, EventSubtitle, EventTime, EventLocation, TeaserCrossLine, } from './EventsComponents';
import { isPastEvent, rotateTeaserCrossLine, } from './HelpersEvents';
import useMedia from '../../../../hooks/useMedia';
import Embed from '../../../Embed/Embed';

Events.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const teasers = [ MainTeaser, GeneralTeaser, ];

const areasTemplate = listLength => {
  let sString = '"MainTeaser" ';

  for (let i = 1; i <= listLength; i += 1) {
    sString += ` "Teaser${i}"`;
  }

  let lString = '"MainTeaser MainTeaser" ';

  for (let i = 1; i < listLength; i += 2) {
    lString += `
    ". ."
    "Teaser${i} Teaser${i + 1}" `;
  }
  return [
    { until: 'm', value: sString, },
    {
      from: 'm',
      value: lString,
    },
  ];
};

export default function Events({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, } = list || {};
  if (!(items && items.length)) return null;

  return (
    <ListView
      areasTemplate={areasTemplate(items.length)}
      colTemplate={[
        { until: 'm', value: '1fr', },
        { from: 'm', value: '1fr 1fr', },
      ]}
      sectionMiscStyles={{
        marginTop: '0',
      }}
      innerBackgroundColor={[ { from: 's', value: 'transparent', }, ]}
      padding={[ { until: 'm', value: '3rem', }, { from: 'm', value: '0', }, ]}
      attrs={{
        'data-test': 'events',
      }}
    >

      <Section isFragment>
        {items.map((data, i) => {
          const index = i === 0 ? 0 : 1;
          if (data && teasers[index] && items[i].inputTemplate === 'TeaserData') {
            const TeaserItem = teasers[i === 0 ? 0 : 1];
            const gridArea = index === 0 ? 'MainTeaser' : `Teaser${i}`;
            return (
              <TeaserItem
                key={data.contentId}
                gridArea={gridArea}
                data={data}
                biAction={setBiAction(i, data, biAction)}
                isLazyloadImages={isLazyloadImages}
                theme={theme}
              />
            );
          }
          return null;
        })}

      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Components                          //
// /////////////////////////////////////////////////////////////////////

const teaserDefaultProps = {
  isLazyloadImages: true,
  biAction: null,
};

const areasMainTeaser = [
  {
    until: 'm', value: `
    "media"
    "date"
    "title"
    "participates"
    "subtitle"
    "time"
    "location"
    `,
  },
  {
    from: 'm',
    value: `
    "media . date"
    "media . title"
    "media . participates"
    "media . subtitle"
    "media . time"
    "media . location"
    `,
  },
];

const areasTeaser = `
"date"
"media"
"title"
"participates"
"subtitle"
"time"
"location"
`;

const mainTeaserRow = [
  { until: 'm', value: 'repeat(4, auto) 1fr auto auto', },
  { from: 'm', until: 'xl', value: '2fr auto auto 3fr auto auto', },
  { from: 'xl', value: '1fr auto auto 2fr auto auto', },
];

const mainTeaserCol = [
  { until: 'm', value: 'auto', },
  { from: 'm', value: '24fr 4rem 12fr', },
];

const teaserRow = 'repeat(4, auto) 1fr auto auto';

const teaserCol = 'auto';

type TeaserPropsType = {
  gridArea: string,
  data: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  theme: Object,
};

MainTeaser.defaultProps = teaserDefaultProps;
function MainTeaser({
  data,
  isLazyloadImages,
  biAction,
  theme,
  gridArea,
}: TeaserPropsType): React.Node {
  const isPast = isPastEvent(data.eventDate);


  const teaserRef = React.useRef();
  const yellowLineRef = React.useRef();
  const eventDateRef = React.useRef();
  const titleRef = React.useRef();
  const pictureRef = React.useRef();

  React.useEffect(() => {
    const teaser = teaserRef.current;
    const yellowLine = yellowLineRef.current;
    const eventDate = eventDateRef.current;
    const title = titleRef.current;
    const picture = pictureRef.current;

    const resizeListener = () => {
      if (isPast && teaser && yellowLine && eventDate && picture && title) {
        rotateTeaserCrossLine({
          teaser,
          yellowLine,
          eventDate,
          picture,
          title,
          isMainTeaser: true,
        });
      }
    };

    // set resize listener
    resizeListener();
    setTimeout(resizeListener, 3000);
    window.addEventListener('resize', resizeListener);

    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [ isPast, teaserRef, yellowLineRef, eventDateRef, pictureRef, ]);

  return (
    <Teaser
      gridArea={gridArea}
      areasTemplate={areasMainTeaser}
      rowTemplate={mainTeaserRow}
      colTemplate={mainTeaserCol}
      colGap={[ { until: 'm', value: '1rem', }, { from: 'm', value: '0', }, ]}
      rowGap="1rem"
      fwRef={teaserRef}
      miscStyles={{
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme.color('primary', '-6'),
      }}
    >
      <TeaserMedia disableAnchor miscStyles={{ zIndex: 3, }} data={data} onClick={biAction}>
        <div ref={pictureRef}>
          <Embed {...data.media} />
        </div>
      </TeaserMedia>
      <EventDate
        {...data}
        isPast={isPast}
        miscStyles={{
          marginTop: [ { until: 'm', value: '-1rem', }, ],
          marginBottom: [ { until: 'm', value: '2rem', }, ],

        }}
        fwRef={eventDateRef}
      />
      <EventTitle {...data} />
      <EventParticipates {...data} />
      <EventSubtitle
        {...data}
        miscStyles={{
          marginBottom: [ { until: 'm', value: '2rem', }, ],
        }}
      />
      <EventTitle {...data} fwRef={titleRef} />
      <EventLocation {...data} />
      {isPast ? (
        <>
          <TeaserCrossLine fwRef={yellowLineRef} />
        </>
      ) : null}
    </Teaser>
  );
}

GeneralTeaser.defaultProps = teaserDefaultProps;

function GeneralTeaser({
  data,
  isLazyloadImages,
  biAction,
  theme,
  gridArea,
}: TeaserPropsType): React.Node {
  const isMobile = useMedia({ query: { until: 'm', }, matchOnServer: true, });
  const isPast = isPastEvent(data.eventDate);

  const teaserRef = React.useRef();
  const yellowLineRef = React.useRef();
  const eventDateRef = React.useRef();
  const titleRef = React.useRef();
  const pictureRef = React.useRef();


  React.useEffect(() => {
    const teaser = teaserRef.current;
    const yellowLine = yellowLineRef.current;
    const eventDate = eventDateRef.current;
    const title = titleRef.current;
    const picture = pictureRef.current;

    const resizeListener = () => {
      if (isPast && teaser && yellowLine && eventDate && picture && title) {
        rotateTeaserCrossLine({ teaser, yellowLine, eventDate, picture, title, });
      }
    };

    // set resize listener
    resizeListener();
    setTimeout(resizeListener, 3000);
    window.addEventListener('resize', resizeListener);

    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [ isPast, teaserRef, yellowLineRef, eventDateRef, pictureRef, ]);

  return (
    <Teaser
      gridArea={gridArea}
      areasTemplate={areasTeaser}
      rowTemplate={teaserRow}
      colTemplate={teaserCol}
      colGap="1rem"
      rowGap="1rem"
      gridGap={null}
      fwRef={teaserRef}
      miscStyles={{
        position: ' relative',
        overflow: 'hidden',
        backgroundColor: theme.color('primary', '-6'),
      }}
    >
      <EventDate {...data} isPast={isPast} fwRef={eventDateRef} miscStyles={{ marginBottom: '2rem', zIndex: isMobile ? 3 : undefined, }} />
      <TeaserMedia disableAnchor miscStyles={{ zIndex: 3, }} data={data} onClick={biAction}>
        <div ref={pictureRef}>
          <Embed {...data.media} />
        </div>
      </TeaserMedia>
      <EventTitle {...data} fwRef={titleRef} />
      <EventParticipates {...data} />
      <EventSubtitle {...data} miscStyles={{ marginTop: '2rem', marginBottom: '2rem', }} />
      <EventTime {...data} />
      <EventLocation {...data} />
      {
        isPast ? (
          <>
            <TeaserCrossLine fwRef={yellowLineRef} />
          </>
        ) : null
      }
    </Teaser>
  );
}
