/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type {
  StyleProps,
} from '@haaretz/htz-css-tools';
import {
  parseStyleProps,
} from '@haaretz/htz-css-tools';
import Time from '../../../Time/Time';
import { monthToHebrew, setPlayButtonPosition, } from './HelpersEvents';
import IconArrowRounded from '../../../Icon/icons/IconArrowRounded';
import IconLocation from '../../../Icon/icons/IconLocation';
import HtzLink from '../../../HtzLink/HtzLink';
import IconPlay from '../../../Icon/icons/IconPlay';

const playButtonStyle = ({ theme, }) => {
  const backgroundColor = theme.color('audioPlayer', 'playBg');
  return {
    ':-moz-focusring': { outline: `2px dotted ${theme.color('audioPlayer', 'mozFocusRing')}`, },
    position: 'relative',
    marginRight: '5rem',
    marginLeft: '5rem',
    backgroundColor,
    width: '47px',
    height: '47px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animationDuration: '1.3s',
    animationDelay: '0.6s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-out',
    transitionProperty: 'transform',
    transitionDuration: '0.4s',
  };
};

const playButtonInnerStyle = ({ theme, }) => ({
  ':-moz-focusring': { outline: 'none', },
  outline: 'none',
  position: 'absolute',
  top: '0',
  bottom: '0',
  start: '0',
  end: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

type EventDatePropTypes = {
  /** className passed on to the Time component */
  className: ?string,
  /** The event date in unixtime (ms) */
  eventDate: ?number,
  isPast: boolean,
  miscStyles: ?StyleProps,
  /**
  * Forwarded ref
  */
  fwRef?: ?{ current: null | React$ElementRef<any>, } | ?(null | React$ElementRef<any>) => void,
};


EventDate.defaultProps = {
  className: null,
  eventDate: null,
  isPast: false,
  miscStyles: null,
  fwRef: null,
};

export function EventDate({
  className,
  eventDate,
  isPast,
  miscStyles,
  fwRef,
}: EventDatePropTypes): React.Node {
  const { css, theme, } = useFela();
  if (!eventDate) return null;
  const dateFormat = theme.timeLabels.dateFormat;
  const monthFormat = theme.timeLabels.monthFormat;

  return (
    <div
      className={css({
        gridArea: 'date',
        position: 'relative',
        overflow: 'hidden',
        color: theme.color('secondary'),
        zIndex: 1,
        extend: [
          // Trump all other styles with those defined in `miscStyles`
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      <div
        className={
          css({
            fontWeight: 'bold',
            zIndex: 0,
            height: '0.9em',
            // marginTop: '3rem',
            extend: [
              theme.type(9, { untilBp: 's', }),
              theme.type(10, { fromBp: 's', }),
              // theme.mq({ until: 'm', }, { marginTop: '2rem', height: '0.85em', }),
            ],
          })
        }
        ref={fwRef}
      >
        <Time
          format={dateFormat}
          time={eventDate}
          render={(date => (
            <span className={css({
              marginStart: date[date.length - 1] === '1' ? '-1.5rem' : undefined,
            })}
            >
              {date}
            </span>
          ))}
        />
      </div>
      <div>
        <Time
          className={css({
            fontWeight: 300,
            zIndex: 0,
            extend: [
              theme.type(1),
            ],
          })}
          format={monthFormat}
          time={eventDate}
          render={month => (monthToHebrew(month))}
        />
      </div>
    </div>
  );
}

type EventTitlePropTypes = {
  /** className passed on to the Time component */
  className: ?string,
  /** The event date in unixtime (ms) */
  title: ?string,
  /**
   * Forwarded ref
   */
  fwRef?: ?{ current: null | React$ElementRef<any>, } | ?(null | React$ElementRef<any>) => void,
};


EventTitle.defaultProps = {
  className: null,
  title: null,
  fwRef: null,
};

export function EventTitle({
  className,
  title,
  fwRef,
}: EventTitlePropTypes): React.Node {
  const { css, theme, } = useFela();
  if (!title) return null;

  return (
    <div
      className={css({
        gridArea: 'title',
        fontWeight: 'bold',
        color: theme.color('secondary'),
      })}
      ref={fwRef}
    >
      {title}
    </div>
  );
}


type EventParticipatesPropTypes = {
  /** className passed on to the Time component */
  className: ?string,
  /** The event date in unixtime (ms) */
  participates: ?string,
};


EventParticipates.defaultProps = {
  className: null,
  participates: null,
};

export function EventParticipates({
  className,
  participates,
}: EventParticipatesPropTypes): React.Node {
  const { css, theme, } = useFela();
  if (!participates) return null;

  return (
    <div
      className={css({
        gridArea: 'participates',
        color: theme.color('secondary'),
        extend: [
          theme.type(-1),
        ],
      })}
    >
      {participates}
    </div>
  );
}


type EventSubtitlePropTypes = {
  /** className passed on to the Time component */
  className: ?string,
  /** The event date in unixtime (ms) */
  subtitle: ?string,
  miscStyles: ?StyleProps,
};


EventSubtitle.defaultProps = {
  className: null,
  subtitle: null,
  miscStyles: null,
};

export function EventSubtitle({
  className,
  subtitle,
  miscStyles,
}: EventSubtitlePropTypes): React.Node {
  const { css, theme, } = useFela();
  if (!subtitle) return null;

  return (
    <div
      className={css({
        gridArea: 'subtitle',
        color: theme.color('secondary'),
        extend: [
          // Trump all other styles with those defined in `miscStyles`
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      {subtitle}
    </div>
  );
}


type EventTimePropTypes = {
  /** className passed on to the Time component */
  className: ?string,
  /** The event date in unixtime (ms) */
  eventDate: ?number,
};


EventTime.defaultProps = {
  className: null,
  eventDate: null,
};

export function EventTime({
  className,
  eventDate,
}: EventTimePropTypes): React.Node {
  const { css, theme, } = useFela();
  if (!eventDate) return null;

  return (
    <div
      className={css({
        gridArea: 'time',
        color: theme.color('secondary'),
        fontWeight: 'bold',
        extend: [
          theme.type(-1),
        ],
      })}
    >
      <Time format={theme.timeLabels.sameDayTimeFormat} time={eventDate} />
    </div>
  );
}


type EventLocationPropTypes = {
  /** className passed on to the Time component */
  className: ?string,
  /** The event date in unixtime (ms) */
  location: ?number,
  path: ?string,
};


EventLocation.defaultProps = {
  className: null,
  location: null,
  path: null,
};

export function EventLocation({
  className,
  location,
  path,
}: EventLocationPropTypes): React.Node {
  const { css, theme, } = useFela();
  if (!location) return null;

  return (
    <div
      className={css({
        gridArea: 'location',
        color: theme.color('secondary'),
        fontWeight: 'bold',
        position: 'relative',
        marginBottm: '2rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        extend: [
          theme.type(-1),
        ],
      })}
    >
      <div
        className={css({
          marginLeft: '3.5rem',
        })
        }
      >
        <span className={css({
          marginLeft: '0.5rem',
        })}
        >
          <IconLocation
            miscStyles={{
              verticalAlign: 'middle',
            }}
          />
        </span>
        {location}
      </div>
      <HtzLink
        href={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconArrowRounded
          size={3}
          miscStyles={{
            color: theme.color('secondary'),
            marginLeft: '0.5rem',
          }}
        />
      </HtzLink>
    </div>
  );
}

export function TeaserCover() {
  const { css, theme, } = useFela();

  return (
    <div className={css({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.color('primary', '-6'),
      opacity: 0.5,
      zIndex: '2',
    })}
    />
  );
}


TeaserCrossLine.defaultProps = {
  fwRef: null,
};

type TeaserCrossLinePropTypes = {
  /**
   * Forwarded ref
   */
  fwRef?: ?{ current: null | React$ElementRef<any>, } | ?(null | React$ElementRef<any>) => void,
}

export function TeaserCrossLine({ fwRef, }: TeaserCrossLinePropTypes) {
  const { css, theme, } = useFela();

  return (
    <div
      className={css({
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: theme.color('quaternary', 'base'),
        opacity: 0.5,
        zIndex: '0',
        transformOrigin: 'bottom left',
        extend: [
          theme.mq({ until: 'm', }, { width: '8rem', }),
          theme.mq({ from: 'm', }, { width: '10rem', }),
        ],
      })}
      ref={fwRef}
    />
  );
}


type EventPlayButtonPropTypes = {
  pictureRef?: null | React$ElementRef<any>,
  miscStyles: ?StyleProps,
  path: ?string,
};


PlayButton.defaultProps = {
  pictureRef: null,
  miscStyles: null,
  path: null,
};

export function PlayButton({
  path,
  pictureRef,
  miscStyles,
}: EventPlayButtonPropTypes) {
  const { css, theme, } = useFela();
  const playButtonRef = React.useRef();

  React.useEffect(() => {
    const resizeListener = () => {
      setPlayButtonPosition(pictureRef, playButtonRef);
    };

    resizeListener();
    // set resize listener
    window.addEventListener('resize', resizeListener);

    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [ pictureRef, ]);


  return (
    <div
      className={css({
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '4',
        extend: [
          // Trump all other styles with those defined in `miscStyles`
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
      ref={playButtonRef}
    >
      <HtzLink
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        className={css(playButtonStyle)}
      >
        <div className={css(playButtonInnerStyle)}>
          <IconPlay
            aria-hidden
            size={[
              { until: 'xl', value: 3.5, },
              { from: 'xl', value: 3, },
            ]}
            color={[ 'audioPlayer', 'playBtn', ]}
            miscStyles={{ transform: 'translateX(2px)', }}
          />
        </div>
      </HtzLink>
    </div>
  );
}

YouTubeIframe.defaultProps = {
  iFrame: null,
  miscStyles: null,
  isMainTeaser: false,
  fwRef: null,
};

type YouTubeIframePropTypes = {
  /** The event date in unixtime (ms) */
  iFrame: ?string,
  miscStyles: ?StyleProps,
  isMainTeaser: boolean,
  /**
   * Forwarded ref
   */
  fwRef?: ?{ current: null | React$ElementRef<any>, } | ?(null | React$ElementRef<any>) => void,
};


export function YouTubeIframe({
  iFrame,
  miscStyles,
  isMainTeaser,
  fwRef,
}: YouTubeIframePropTypes): React.Node {
  const { css, theme, } = useFela();
  if (!iFrame) return null;
  return (
    <div
      className={css({
        position: 'relative',
        height: 0,
        paddingBottom: '56.25%',
        zIndex: 3,
        '> iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
        extend: [
          // Trump all other styles with those defined in `miscStyles`
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ].concat(isMainTeaser
          ? [
            theme.mq({ until: 's', }, { paddingBottom: '48%', }), /* 16:9 */
            theme.mq({ from: 's', until: 'l', }, { paddingBottom: '51%', }), /* 16:9 */
            theme.mq({ from: 'l', }, { paddingBottom: '52.25%', }), /* 16:9 */
          ] : [
            { paddingBottom: '56.25%', }, /* 16:9 */
          ]),
      })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: iFrame, }}
      ref={fwRef}
    />
  );
}
