/* global document */
const months = [
  { value: 1, display: 'ינואר', },
  { value: 2, display: 'פברואר', },
  { value: 3, display: 'מרץ', },
  { value: 4, display: 'אפריל', },
  { value: 5, display: 'מאי', },
  { value: 6, display: 'יוני', },
  { value: 7, display: 'יולי', },
  { value: 8, display: 'אוגוסט', },
  { value: 9, display: 'ספטמבר', },
  { value: 10, display: 'אוקטובר', },
  { value: 11, display: 'נובמבר', },
  { value: 12, display: 'דצמבר', },
];

export function monthToHebrew(month) {
  return months.find(m => m.value === +month).display;
}

export function isPastEvent(eventDate) {
  const now = new Date();

  return new Date(eventDate) < now;
}

export function rotateTeaserCrossLine({
  teaser,
  yellowLine,
  eventDate,
  picture,
  title,
  isMainTeaser,
}) {
  const teaserHeight = teaser.offsetHeight;
  const teaserWidth = teaser.offsetWidth;
  const pictureHeight = picture.offsetHeight;

  const titleWidth = title.offsetWidth;

  const isMobile = document.body.offsetWidth < 768;

  const yellowLineHeight = Math.sqrt((teaserHeight ** 2) + (teaserWidth ** 2));

  const width = (isMainTeaser && !isMobile) ? titleWidth : teaserWidth;

  const height = isMobile && isMainTeaser
    ? teaserHeight - pictureHeight
    : teaserHeight;

  let yellowLineWidth = yellowLine.offsetWidth;

  const baseAngel = 90 - (Math.atan(
    (height + yellowLineWidth) / (width)
  ) * (180 / Math.PI));

  yellowLineWidth /= Math.sin((baseAngel - 3) * Math.PI / 180);

  const angel = 90 - (Math.atan(
    (height + yellowLineWidth) / (width)
  ) * (180 / Math.PI));

  // eslint-disable-next-line no-param-reassign
  yellowLine.style.height = `${yellowLineHeight * 1.5}px`;
  // eslint-disable-next-line no-param-reassign
  yellowLine.style.transform = `rotate(${angel}deg)`;
}

export function isYoutube({ embed, }) {
  return embed.inputTemplate === 'Youtube'
  && typeof embed.source === 'string';
}


export function setPlayButtonPosition(pictureRef, buttonRef) {
  if (pictureRef.current && buttonRef.current) {
    const pictureHeigth = pictureRef.current.offsetHeight;
    const buttonHeigth = buttonRef.current.offsetHeight;

    // eslint-disable-next-line no-param-reassign
    buttonRef.current.style.top = `${(pictureHeigth - buttonHeigth) / 2}px`;
    // eslint-disable-next-line no-param-reassign
    buttonRef.current.style.transform = 'translate(-50%, 0)';
  }
}
